.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-calendar {
  color: black;

  
}

.react-calendar   button {
  color: black;
  border: none;
  background-color: white;
  font-family: 'Metropolis', sans-serif;
  font-size: 12pt;
  line-height: 3em;
  height: 3em;
  cursor: pointer;
}

.react-calendar   button:hover {
  background-color: #24CDC3;
  border-radius: 1em;
  font-weight: bold;
  color: white;
  border: 1px solid #24CDC3;
}

.react-calendar__navigation {
  height: 3em;
}

.react-calendar__navigation__arrow {
  width: 3em;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  height: 3em;
  color: black;
  font-weight: bold;
  text-decoration: none;

}

button.primary {
  font-family: 'Metropolis', sans-serif;
  font-size: 12pt;
  background-color: #24CDC3;
  border: none;
  line-height: 2em;
  min-width: 10em;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.center {
  margin: 0 auto;
}

.calendar {
  max-width: 330px;
}

.highlight {
  border: 1px solid #24CDC3;
  max-width: 300px;
  padding: 1em;
  font-weight: bold;
  border-radius: 5px;
}

.padding-10 {
  padding: 10px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.full-width {
  width: 100%;
}

#siapdok-room-wrapper {
  width: calc(100vw - 40px) !important;
  padding: 20px;
}

#siapdok-room {
  zoom: 0.8;
}

#siapdok-room iframe {
  border-radius: 20px;
}